.b-shop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &--shopPage {
    margin-top: 60px;
  }
}

.b-shop__heading {
  font-family: var(--music-font);
  font-weight: 400;
  font-size: 22px;
  margin-top: 0;
  text-shadow: 2px 2px 4px var(--bcg-dark);
  letter-spacing: 8px;
  text-transform: uppercase;
  margin: 20px 0;
  @media (min-width: 576px) {
    font-size: 60px;
  }
}

.b-shopPage__heading {
  margin: 0 auto 20px auto;
  text-align: center;
  padding: 0 20px;
  &--title {
    @media (min-width: 1024px) {
      text-transform: uppercase;
    }
  }
}

.b-shopPage__gallery {
  display: flex;
  flex-wrap: wrap;
}

.b-shopPage__card {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  @media (min-width: 600px) {
    width: 50%;
  }
  @media (min-width: 800px) {
    width: 33.3333%;
  }
  @media (min-width: 1200px) {
    width: 25%;
  }
}

.b-shopPage__image {
  width: 100%;
  border-radius: 5px;
  &--back {
    opacity: 0;
    position: absolute;
    top: 20px;
    left: 20px;
    left: 20px;
    width: calc(100% - 40px);
    &:hover {
      opacity: 1;
    }
  }
}

.b-shopPage__text {
  background-color: var(--bcg-card);
  position: relative;
  top: -25px;
  border-radius: 10px;
  padding: 20px;
  color: var(--text);
  line-height: 1.5;
}

.b-shopPage__title {
  margin: 0;
  font-size: 16px;
  font-family: var(--nav-font);
  text-transform: uppercase;
  letter-spacing: normal;
}

.b-shopPage__desc {
  margin: 0;
  font-size: 15px;
}

.b-shopPage__price {
  font-size: 14px;
  font-family: var(--nav-font);
}
