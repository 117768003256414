.b-bcg {
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  &--hero {
    background-image: url(../../public/images/heroWeb.jpg);
    @media (max-width: 600px) {
      background-image: url(../../public/images/heroMobile.jpg);
      background-attachment: unset;
    }
  }
  &--music {
    background-image: url(../../public/images/angels.jpg);
    @media (max-width: 600px) {
      background-image: url(../../public/images/bcgMusic2.jpg);
    }
  }
  &--shop {
    background-image: url(../../public/images/bcg-shop.jpg);
    background-position: top;
  }
}
