.each-slide-effect__image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 80vh;
  z-index: 0;
  background-attachment: fixed;
  background-position: top;
  &--one {
    background-image: url(../../public/images/1.jpg);
  }
  &--seven {
    background-image: url(../../public/images/8.png);
  }
  &--two {
    background-image: url(../../public/images/3.jpg);
  }
  &--three {
    background-image: url(../../public/images/10.png);
  }
  &--four {
    background-image: url(../../public/images/5.jpg);
  }
  &--five {
    background-image: url(../../public/images/7.jpg);
  }
  &--six {
    background-image: url(../../public/images/2.jpg);
    background-position: center
  }
  &--eight {
    background-image: url(../../public/images/11.jpg);
  }
  &--nine {
    background-image: url(../../public/images/13.jpg);
  }
  &--ten {
    background-image: url(../../public/images/9.jpg);
  }
  &--eleven {
    background-image: url(../../public/images/14.jpg);
  }
  &--twelve {
    background-image: url(../../public/images/12.jpg);
  }
  &--thirteen {
    background-image: url(../../public/images/4.jpg);
  }
  &--fourteen {
    background-image: url(../../public/images/6.jpg);
  }
  &--fifteen {
    background-image: url(../../public/images/15.jpeg);
  }
  &--sixteen {
    background-image: url(../../public/images/16.jpeg);
  }
  &--seventeen {
    background-image: url(../../public/images/17.jpeg);
  }
}

.react-slideshow-wrapper.slide {
  z-index: -100;
}

.react-slideshow-container .default-nav {
  background: transparent !important;
  margin: 0 20px;
}

svg {
  fill: var(--text);
}
