:root {
  --nav: #bca16d;
  --heading: #d0d0d0;
  --text: #fff;
  --bcg: #1c1c1d;
  --bcg-card: #5e5b55;
  --bcg-dark: #000;
  --bcg-btn: rgba(0, 0, 0, 0.568);
  --bcg-menu: #1c1c1dec;
}
