.b-header {
    background-color: var(--bcg);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    z-index: 10;
    &--desk {
        padding: 10px 40px;
    }
}

.b-header__container {
    display: flex;
    align-items: center;
}

.b-header__logo {
    &--mobile {
        max-width: 30px;
    }
    &--desk {
        max-width: 50px;
    }
}