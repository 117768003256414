.b-btn {
  text-transform: uppercase;
  font-size: 16px;
  border: 2px solid var(--text);
  padding: 10px 20px;
  background-color: var(--bcg-btn);
  text-shadow: 1px 1px 1px var(--text);
  letter-spacing: 3px;
  color: var(--text);
  transition: all 0.2s ease;
  &:hover {
    border-color: var(--nav);
    background-color: var(--nav);
  }
  &--tour {
    font-size: 12px;
    text-decoration: none;
    padding: 10px;
  }
}

.b-header__button {
  background: transparent;
  border: none;
  img {
    z-index: 10;
  }
}
