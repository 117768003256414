body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--body-text);
  color: var(--text);
  background-color: var(--bcg);
  line-height: 1.8;
  font-size: 17px;
  letter-spacing: 0.6px;
}

