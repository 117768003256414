.b-contact {
    margin-top: 30px;
}

.b-contact__text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    @media (min-width: 800px) {
        flex-direction: row;
        justify-content: flex-start;
        &--left {
            flex-direction: column;
            width: 60%;
            padding: 0 80px 0 50px;
        }
    }
}

.b-contact__heading {
    margin: 40px 0 10px 0;
    @media (min-width: 576px) {
        padding: 0 60px 0 0px;
        margin-top: 10px;
        font-size: 28px;
    }
}

.b-contact__social {
    border-top: 2px solid var(--nav);
    display: flex;
    align-items: center;
    justify-content: center;
}

.b-contact__icon {
    width: 20px;
    border-radius: 5px;
}

.b-contact__text {
    color: var(--text);
    padding-left: 5px;
    transition: color 0.3s ease;
    @media (min-width: 800px) {
      padding-left: 15px;
      font-weight: 700;
      font-size: 20px;
    }
    &:hover {
      color: var(--nav);
    }
}