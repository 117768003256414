.b-mobileNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: var(--bcg-menu);
  z-index: -1;
}

.menu-item {
  text-decoration: none;
  color: var(--nav);
  font-family: var(--nav-font);
  text-transform: uppercase;
  font-size: 40px;
  margin: 10px;
}

.b-mobileNav__show {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s ease-in-out;
  &--open {
    transform: translateY(0px);
  }
  &--close {
    transform: translateY(-1000px);
  }
}
